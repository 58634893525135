import './App.css';
import NavigationComponent from './NavigationComponent';


function App() {
  return (
    <div className="App">
        <NavigationComponent/>
    </div>
  );
}

export default App;
